import styled, { css } from 'styled-components/macro';

import palette from '../../../theme/palette';

type ItemProps = Readonly<{
  highlighted: boolean;
  keyboardHighlight: boolean;
  header: boolean;
  selected: boolean;
}>;

const itemColors = ({
  selected, header, highlighted, keyboardHighlight,
}: ItemProps) => {
  const highlightColor = keyboardHighlight
    ? palette.inverted.white
    : palette.greyscale[10];

  if (highlighted) {
    return css`
      background-color: ${highlightColor};
      border-radius: ${({ theme }) => theme.scTheme.borders.radii.md};
      box-shadow: ${({ theme }) => keyboardHighlight && theme.scTheme.shadows.focus.primary.full};
      z-index: ${({ theme }) => theme.scTheme.zindex.focused};
    `;
  }

  return css`
    background-color: ${({ theme }) => (selected && header
    ? theme.scTheme.palette.primary[10] : theme.scTheme.palette.inverted.white)};
  `;
};

const DropdownItemContainer = styled.div<ItemProps>`
  align-items: center;
  cursor: ${({ header }) => (header ? 'cursor' : 'pointer')};
  display: flex;
  ${itemColors}
  position: relative;
`;

export default DropdownItemContainer;
