/* eslint-disable */
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// use `yarn generate:graphql to regenerate


  export type ScalarParsers = Partial<Readonly<{
    MonthDay: (arg: Date | null) => string | null;
		Date: (arg: Date | null) => string | null;
		LocalDate: (arg: Date | null) => string | null;
		LocalDateTime: (arg: Date | null) => string | null;
		LocalTime: (arg: Date | null) => string | null;
		DateTime: (arg: Date | null) => string | null;
		OffsetDateTime: (arg: Date | null) => string | null;
		YearMonth: (arg: Date | null) => string | null;
		YearInput: (arg: number | null) => number | null;
		ID: (arg: string | null) => string | null;
		BenefitVariantProductId: (arg: string | null) => string | null;
		InvoiceDetailsId: (arg: string | null) => string | null;
		VatTypeInput: (arg: "VatNotApplicable" | "ExemptFromVAT" | number | null) => "VatNotApplicable" | "ExemptFromVAT" | number | null;
		NfsErrorId: (arg: string | null) => string | null;
		ActivityId: (arg: string | null) => string | null;
		AlternativeBenefitsGroupId: (arg: string | null) => string | null;
		BankAccountId: (arg: string | null) => string | null;
		CompanyId: (arg: string | null) => string | null;
		ClientContractId: (arg: string | null) => string | null;
		ClientPositionId: (arg: string | null) => string | null;
		ProjectId: (arg: string | null) => string | null;
		ProjectUserId: (arg: string | null) => string | null;
		SpaceId: (arg: string | null) => string | null;
		ContractId: (arg: string | null) => string | null;
		UserId: (arg: string | null) => string | null;
		AdminId: (arg: string | null) => string | null;
		AuditEntryId: (arg: string | null) => string | null;
		BonusId: (arg: string | null) => string | null;
		OfficeId: (arg: string | null) => string | null;
		ClientId: (arg: string | null) => string | null;
		WorkTagId: (arg: string | null) => string | null;
		WorkRecordId: (arg: string | null) => string | null;
		OnCallRecordId: (arg: string | null) => string | null;
		AbsenceRecordId: (arg: string | null) => string | null;
		InvoiceRecordId: (arg: string | null) => string | null;
		ReportingPeriodId: (arg: string | null) => string | null;
		RevenueAdministrationOfficeId: (arg: number | null) => number | null;
		Cents: (arg: number | null) => number | null;
		Minutes: (arg: number | null) => number | null;
		BigDecimal: (arg: number | null) => number | null;
		Unit: (arg: Record<never, never> | null) => undefined | null;
		URL: (arg: string | null) => string | null;
		Cursor: (arg: string | null) => string | null;
		BasisPoint: (arg: number | null) => number | null;
		Currency: (arg: string | null) => string | null;
		BenefitId: (arg: string | null) => string | null;
		BenefitVariantId: (arg: string | null) => string | null;
		AssignedVariantId: (arg: string | null) => string | null;
		Json: (arg: string | null) => string | null;
		EntityVersion: (arg: number | null) => number | null;
		Days: (arg: number | null) => number | null;
		Year: (arg: number | null) => number | null;
		InvoiceNumber: (arg: string | null) => string | null;
		URI: (arg: string | null) => string | null;
		Upload: (arg: FileList[number] | null) => undefined | null;
		Iban: (arg: string | null) => string | null;
		Bic: (arg: string | null) => string | null;
		TaxNumber: (arg: string | null) => string | null;
		CountryCode: (arg: string | null) => string | null;
		PESEL: (arg: string | null) => string | null;
		EmailAddress: (arg: string | null) => string | null;
		REGON: (arg: string | null) => string | null;
		PhoneNumber: (arg: string | null) => string | null;
		RegisterId: (arg: string | null) => string | null
  }>>

  export type Path = [string, keyof ScalarParsers];

  export const scalarPaths: Record<string, Path[]> = {
    UserBenefitSections_AssignedBenefitVariants: [["contractId","ContractId"]],
		User_DirectReportingStructure: [],
		InvoicesDrawer_Invoices: [["contractId","ContractId"],["from","YearMonth"],["to","YearMonth"]],
		User_InvoicesDrawer_UpdateInvoice: [["contractId","ContractId"],["yearMonth","YearMonth"],["invoiceDetailsId","InvoiceDetailsId"],["invoiceDetails.file.value","Upload"],["invoiceDetails.number.value","InvoiceNumber"]],
		User_InvoicesDrawer_CreateInvoice: [["contractId","ContractId"],["yearMonth","YearMonth"],["invoiceDetails.file","Upload"],["invoiceDetails.id","InvoiceDetailsId"],["invoiceDetails.number","InvoiceNumber"]],
		User_InvoicesDrawer_RemoveInvoice: [["invoiceDetailsId","InvoiceDetailsId"]],
		User_ProjectsColorsProvider_AllProjects: [],
		User_TimeReportBlockedMessage_SpaceOwner: [],
		TimeReportBulkEditToolbar_DeleteSelected: [["workRecordIds","WorkRecordId"],["onCallRecordIds","OnCallRecordId"],["absenceRecordIds","AbsenceRecordId"]],
		TimeReportDashboardProvider_Validations: [["contractId","ContractId"],["date","YearMonth"]],
		User_TimeReportDashboardProvider_ContractInfo: [["contractId","ContractId"]],
		User_TimeReportDashboardProvider: [["dashboardFrom","LocalDate"],["dashboardTo","LocalDate"],["projectsFrom","OffsetDateTime"],["projectsTo","OffsetDateTime"],["contractId","ContractId"]],
		TimeReportEntryModal_CreateWorkRecord: [["workRecords.contractId","ContractId"],["workRecords.date","LocalDate"],["workRecords.duration","Minutes"],["workRecords.id","WorkRecordId"],["workRecords.projectId","ProjectId"],["workRecords.workTagId","WorkTagId"],["workRecords.workTime.break","Minutes"],["workRecords.workTime.timeFrom","LocalTime"],["workRecords.workTime.timeTo","LocalTime"]],
		TimeReportEntryModal_UpdateWorkRecord: [["workRecordId","WorkRecordId"],["projectId.value","ProjectId"],["workTagId","WorkTagId"],["date","LocalDate"],["duration.value","Minutes"],["workTime.value.break","Minutes"],["workTime.value.timeFrom","LocalTime"],["workTime.value.timeTo","LocalTime"]],
		TimeReportEntryModal_DeleteWorkRecord: [["workRecordId","WorkRecordId"]],
		TimeReportEntryModal_CreateOnCallRecord: [["onCallRecords.contractId","ContractId"],["onCallRecords.date","LocalDate"],["onCallRecords.id","OnCallRecordId"],["onCallRecords.projectId","ProjectId"]],
		TimeReportEntryModal_UpdateOnCallRecord: [["onCallRecordId","OnCallRecordId"],["projectId","ProjectId"],["date","LocalDate"]],
		TimeReportEntryModal_DeleteOnCallRecord: [["onCallRecordId","OnCallRecordId"]],
		TimeReportEntryModal_CreateAbsenceRecord: [["absenceRecords.contractId","ContractId"],["absenceRecords.customDurationPerDay","Minutes"],["absenceRecords.from","LocalDate"],["absenceRecords.id","AbsenceRecordId"],["absenceRecords.to","LocalDate"]],
		TimeReportEntryModal_UpdateAbsenceRecord: [["id","AbsenceRecordId"],["dateFrom","LocalDate"],["dateTo","LocalDate"],["customDurationPerDay.value","Minutes"]],
		TimeReportEntryModal_DeleteAbsenceRecord: [["absenceRecordId","AbsenceRecordId"]],
		User_TimeReportEntryModal_Limits: [["year","Year"],["from","LocalDate"],["to","LocalDate"],["contractId","ContractId"]],
		User_TimeReportFinances: [["contractId","ContractId"],["from","LocalDate"],["to","LocalDate"],["month","YearMonth"]],
		User_TimeReportLeaveAndTraining: [["contractId","ContractId"],["year","Year"],["from","LocalDate"],["to","LocalDate"]],
		User_TimeReportOnCallDetailsModal: [],
		TimeReportStats: [["from","LocalDate"],["to","LocalDate"],["year","Year"],["contractId","ContractId"]],
		TimeReport_WorkTimeInsights: [["from","LocalDate"],["to","LocalDate"],["contractId","ContractId"]],
		SecureRoutesWrapper_Contracts: [],
		TimeReportPreferencesDrawer_Update: [["contractId","ContractId"],["workTagId.value","WorkTagId"],["projectId.value.value","ProjectId"],["fromTime.value","LocalTime"],["toTime.value","LocalTime"]],
		DirectReporting_AssignedProjects: [],
		Drawer: [["id","ID"]],
		RootDrawer: [],
		TopDrawer: []
  }
  