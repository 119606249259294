/* eslint-disable */
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// use `yarn generate:graphql to regenerate


      export type PossibleTypesResultData = {
  "possibleTypes": {
    "AbsenceRecordOrNfsError": [
      "AbsenceRecord",
      "NfsError"
    ],
    "AbstractDrawer": [
      "InvoicesDrawer",
      "TimeReportPreferencesDrawer"
    ],
    "AnnualLeaveSummaryOrNfsError": [
      "AnnualLeaveSummary",
      "NfsError"
    ],
    "BenefitAdditionalPeople": [
      "BenefitAdditionalAdults",
      "BenefitAdditionalFamily",
      "BenefitAdditionalKids"
    ],
    "BenefitCommon": [
      "Benefit",
      "SubBenefit"
    ],
    "Bonus": [
      "AppreciationBonus",
      "EmployeeReferralBonus",
      "LanguageLearningBonus",
      "OtherBonus",
      "RemoteWorkBonus",
      "RemoteWorkEquipmentBonus",
      "TrainingPackageBonus"
    ],
    "BooleanResponseOrNfsError": [
      "BooleanResponse",
      "NfsError"
    ],
    "ClosedEndLocalDateRangeOrNfsError": [
      "ClosedEndLocalDateRange",
      "NfsError"
    ],
    "Contract": [
      "B2BContract",
      "EmploymentContract",
      "Subcontract"
    ],
    "ContractResponseOrNfsError": [
      "ContractResponse",
      "NfsError"
    ],
    "ContractSalaryDetails": [
      "B2BContractSalaryDetails",
      "EmploymentContractSalaryDetails"
    ],
    "ContractSalaryDetailsResponseOrNfsError": [
      "ContractSalaryDetailsResponse",
      "NfsError"
    ],
    "Highlight": [],
    "InvoiceDetailsOrNfsError": [
      "InvoiceDetails",
      "NfsError"
    ],
    "LeaveBalanceOrNfsError": [
      "LeaveBalance",
      "NfsError"
    ],
    "ListOfAbsenceRecordResponseOrNfsError": [
      "ListOfAbsenceRecordResponse",
      "NfsError"
    ],
    "ListOfAssignedProjectResponseOrNfsError": [
      "ListOfAssignedProjectResponse",
      "NfsError"
    ],
    "ListOfContractSalaryDetailsResponseOrNfsError": [
      "ListOfContractSalaryDetailsResponse",
      "NfsError"
    ],
    "ListOfKVWorkTagIdMinutesResponseOrNfsError": [
      "ListOfKVWorkTagIdMinutesResponse",
      "NfsError"
    ],
    "ListOfOnCallRecordResponseOrNfsError": [
      "ListOfOnCallRecordResponse",
      "NfsError"
    ],
    "ListOfProjectIdResponseOrNfsError": [
      "ListOfProjectIdResponse",
      "NfsError"
    ],
    "ListOfTimeReportValidationResponseOrNfsError": [
      "ListOfTimeReportValidationResponse",
      "NfsError"
    ],
    "ListOfWorkRecordResponseOrNfsError": [
      "ListOfWorkRecordResponse",
      "NfsError"
    ],
    "OnCallMultipliersOrNfsError": [
      "NfsError",
      "OnCallMultipliers"
    ],
    "OnCallRecordOrNfsError": [
      "NfsError",
      "OnCallRecord"
    ],
    "TimeReportOrNfsError": [
      "NfsError",
      "TimeReport"
    ],
    "TimeReportStats": [
      "B2BTimeReportStats",
      "EmploymentTimeReportStats"
    ],
    "TimeReportValidation": [
      "IncompleteEntries",
      "MissingEntries",
      "NegativeFlexTimeBalance"
    ],
    "UnitResponseOrNfsError": [
      "NfsError",
      "UnitResponse"
    ],
    "UserError": [
      "ActiveReportingRangeError",
      "AggregatedValidationErrors",
      "DuplicatedEntityError",
      "EmptyRequestInputError",
      "EntitiesNotFoundError",
      "EntityNotFoundError",
      "EntityVersionMismatched",
      "ForeignKeyConstraintError",
      "IllegalPositionRemovalError",
      "IncompleteArgumentError",
      "IncompleteArgumentsError",
      "InternalNfsError",
      "InvalidArgumentsError",
      "InvalidCompanyTypeError",
      "InvalidContentTypeError",
      "InvalidCursorError",
      "InvalidEmailAddress",
      "InvalidStateError",
      "InvoiceAlreadyAcceptedError",
      "LeaveEntitlementExceededError",
      "MissingFileMetadataError",
      "NBPBaseCurrencyError",
      "OperationNotAvailableError",
      "OtherInvalidRequestError",
      "PaymentCouldNotBeChangedError",
      "PositionInactiveError",
      "TimeBasedActivityOverlapError",
      "TimeBasedEffectiveFromInPastError",
      "TimeReportOverlapError",
      "UnauthorizedOperationError",
      "UnknownContentTypeError",
      "UserFacingAuthzError",
      "ValidationError"
    ],
    "UserOrNfsError": [
      "NfsError",
      "User"
    ],
    "VatType": [
      "VatApplied",
      "VatNotApplied"
    ],
    "WorkRecordOrNfsError": [
      "NfsError",
      "WorkRecord"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AbsenceRecordOrNfsError": [
      "AbsenceRecord",
      "NfsError"
    ],
    "AbstractDrawer": [
      "InvoicesDrawer",
      "TimeReportPreferencesDrawer"
    ],
    "AnnualLeaveSummaryOrNfsError": [
      "AnnualLeaveSummary",
      "NfsError"
    ],
    "BenefitAdditionalPeople": [
      "BenefitAdditionalAdults",
      "BenefitAdditionalFamily",
      "BenefitAdditionalKids"
    ],
    "BenefitCommon": [
      "Benefit",
      "SubBenefit"
    ],
    "Bonus": [
      "AppreciationBonus",
      "EmployeeReferralBonus",
      "LanguageLearningBonus",
      "OtherBonus",
      "RemoteWorkBonus",
      "RemoteWorkEquipmentBonus",
      "TrainingPackageBonus"
    ],
    "BooleanResponseOrNfsError": [
      "BooleanResponse",
      "NfsError"
    ],
    "ClosedEndLocalDateRangeOrNfsError": [
      "ClosedEndLocalDateRange",
      "NfsError"
    ],
    "Contract": [
      "B2BContract",
      "EmploymentContract",
      "Subcontract"
    ],
    "ContractResponseOrNfsError": [
      "ContractResponse",
      "NfsError"
    ],
    "ContractSalaryDetails": [
      "B2BContractSalaryDetails",
      "EmploymentContractSalaryDetails"
    ],
    "ContractSalaryDetailsResponseOrNfsError": [
      "ContractSalaryDetailsResponse",
      "NfsError"
    ],
    "Highlight": [],
    "InvoiceDetailsOrNfsError": [
      "InvoiceDetails",
      "NfsError"
    ],
    "LeaveBalanceOrNfsError": [
      "LeaveBalance",
      "NfsError"
    ],
    "ListOfAbsenceRecordResponseOrNfsError": [
      "ListOfAbsenceRecordResponse",
      "NfsError"
    ],
    "ListOfAssignedProjectResponseOrNfsError": [
      "ListOfAssignedProjectResponse",
      "NfsError"
    ],
    "ListOfContractSalaryDetailsResponseOrNfsError": [
      "ListOfContractSalaryDetailsResponse",
      "NfsError"
    ],
    "ListOfKVWorkTagIdMinutesResponseOrNfsError": [
      "ListOfKVWorkTagIdMinutesResponse",
      "NfsError"
    ],
    "ListOfOnCallRecordResponseOrNfsError": [
      "ListOfOnCallRecordResponse",
      "NfsError"
    ],
    "ListOfProjectIdResponseOrNfsError": [
      "ListOfProjectIdResponse",
      "NfsError"
    ],
    "ListOfTimeReportValidationResponseOrNfsError": [
      "ListOfTimeReportValidationResponse",
      "NfsError"
    ],
    "ListOfWorkRecordResponseOrNfsError": [
      "ListOfWorkRecordResponse",
      "NfsError"
    ],
    "OnCallMultipliersOrNfsError": [
      "NfsError",
      "OnCallMultipliers"
    ],
    "OnCallRecordOrNfsError": [
      "NfsError",
      "OnCallRecord"
    ],
    "TimeReportOrNfsError": [
      "NfsError",
      "TimeReport"
    ],
    "TimeReportStats": [
      "B2BTimeReportStats",
      "EmploymentTimeReportStats"
    ],
    "TimeReportValidation": [
      "IncompleteEntries",
      "MissingEntries",
      "NegativeFlexTimeBalance"
    ],
    "UnitResponseOrNfsError": [
      "NfsError",
      "UnitResponse"
    ],
    "UserError": [
      "ActiveReportingRangeError",
      "AggregatedValidationErrors",
      "DuplicatedEntityError",
      "EmptyRequestInputError",
      "EntitiesNotFoundError",
      "EntityNotFoundError",
      "EntityVersionMismatched",
      "ForeignKeyConstraintError",
      "IllegalPositionRemovalError",
      "IncompleteArgumentError",
      "IncompleteArgumentsError",
      "InternalNfsError",
      "InvalidArgumentsError",
      "InvalidCompanyTypeError",
      "InvalidContentTypeError",
      "InvalidCursorError",
      "InvalidEmailAddress",
      "InvalidStateError",
      "InvoiceAlreadyAcceptedError",
      "LeaveEntitlementExceededError",
      "MissingFileMetadataError",
      "NBPBaseCurrencyError",
      "OperationNotAvailableError",
      "OtherInvalidRequestError",
      "PaymentCouldNotBeChangedError",
      "PositionInactiveError",
      "TimeBasedActivityOverlapError",
      "TimeBasedEffectiveFromInPastError",
      "TimeReportOverlapError",
      "UnauthorizedOperationError",
      "UnknownContentTypeError",
      "UserFacingAuthzError",
      "ValidationError"
    ],
    "UserOrNfsError": [
      "NfsError",
      "User"
    ],
    "VatType": [
      "VatApplied",
      "VatNotApplied"
    ],
    "WorkRecordOrNfsError": [
      "NfsError",
      "WorkRecord"
    ]
  }
};
      export default result;
    