/* eslint-disable */
// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// use `yarn generate:graphql to regenerate


import * as policies from './scalars';

export const scalarTypePolicies = {
  AbsenceRecord: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, } },
	AccountingData: { fields: { maternityLeaveAllowance: policies.centsTypePolicy, overtimePayout: policies.centsTypePolicy, ppkAmount: policies.centsTypePolicy, taxPrepayment: policies.centsTypePolicy, taxRefund: policies.centsTypePolicy, zusContribution: policies.centsTypePolicy, } },
	AnnualLeaveEntitlementPeriod: { fields: { createdAt: policies.dateTypePolicy, } },
	B2BContract: { fields: { at: policies.dateTypePolicy, conclusionDate: policies.localDateTypePolicy, createdAt: policies.dateTypePolicy, expectedEndDate: policies.localDateTypePolicy, preselectedFromTime: policies.localDateTypePolicy, preselectedToTime: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, terminationDate: policies.localDateTypePolicy, } },
	B2BContractSalaryDetails: { fields: { transferAmount: policies.centsTypePolicy, yearMonth: policies.dateTypePolicy, } },
	Benefit: { fields: { endDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, } },
	BenefitVariant: { fields: { at: policies.dateTypePolicy, endDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, } },
	CalendarDay: { fields: { date: policies.localDateTypePolicy, } },
	ClosedEndLocalDateRange: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, } },
	ClosedEndMonthRange: { fields: { from: policies.dateTypePolicy, to: policies.dateTypePolicy, } },
	ContractSalaryDetailsId: { fields: { yearMonth: policies.dateTypePolicy, } },
	EmploymentContract: { fields: { at: policies.dateTypePolicy, conclusionDate: policies.localDateTypePolicy, createdAt: policies.dateTypePolicy, expectedEndDate: policies.localDateTypePolicy, preselectedFromTime: policies.localDateTypePolicy, preselectedToTime: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, terminationDate: policies.localDateTypePolicy, } },
	EmploymentContractSalaryDetails: { fields: { transferAmount: policies.centsTypePolicy, yearMonth: policies.dateTypePolicy, } },
	IncomeDetails: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, vat: policies.basisPointTypePolicy, } },
	IncompleteEntries: { fields: { days: policies.arrayTypePolicy(policies.localDateTypePolicy), } },
	LocalDateRange: { fields: { from: policies.localDateTypePolicy, to: policies.localDateTypePolicy, } },
	MissingEntries: { fields: { days: policies.arrayTypePolicy(policies.localDateTypePolicy), } },
	NetGross: { fields: { gross: policies.centsTypePolicy, net: policies.centsTypePolicy, } },
	OnCallLog: { fields: { dates: policies.arrayTypePolicy(policies.localDateTypePolicy), } },
	OnCallRecord: { fields: { date: policies.localDateTypePolicy, } },
	Query: { fields: { remoteWorkReimbursementRate: policies.centsTypePolicy, } },
	RemoteWorkReimbursement: { fields: { rate: policies.centsTypePolicy, } },
	SubBenefit: { fields: { endDate: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, } },
	Subcontract: { fields: { at: policies.dateTypePolicy, conclusionDate: policies.localDateTypePolicy, createdAt: policies.dateTypePolicy, expectedEndDate: policies.localDateTypePolicy, preselectedFromTime: policies.localDateTypePolicy, preselectedToTime: policies.localDateTypePolicy, startDate: policies.localDateTypePolicy, terminationDate: policies.localDateTypePolicy, } },
	TimeBalance: { fields: { at: policies.localDateTypePolicy, } },
	TimeBasedActivityOverlapError: { fields: { date: policies.dateTypePolicy, } },
	TimeBasedEffectiveFromInPastError: { fields: { activityDate: policies.dateTypePolicy, now: policies.dateTypePolicy, } },
	TimeBasedInstantBenefitBuyerType: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantBenefitVariant: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantBoolean: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableBenefitVariantRate: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableNetGross: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableOnCallRate: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearablePerson: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantClearableProjectId: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantCompanyRole: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantCurrency: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantRate: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantSpace: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantVatType: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	TimeBasedInstantWorkingTime: { fields: { applyDate: policies.dateTypePolicy, createdAt: policies.dateTypePolicy, } },
	VatApplied: { fields: { value: policies.basisPointTypePolicy, } },
	WorkRecord: { fields: { date: policies.localDateTypePolicy, } },
	WorkTime: { fields: { timeFrom: policies.localDateTypePolicy, timeTo: policies.localDateTypePolicy, } },
};