import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';

import noop from 'lodash/noop';
import styled, { css } from 'styled-components/macro';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';

import borders from '../../../theme/borders';
import palette from '../../../theme/palette';
import shadows from '../../../theme/shadows';
import space from '../../../theme/space';

import ButtonGroup from '../../atoms/ButtonGroup';
import ActionText from '../ActionText';

const variants = {
  ghost: {
    normal: {
      background: palette.transparent,
      border: null,
      padding: { y: space[300], x: space[300] },
    },
    hover: {
      background: palette.greyscale[10],
      border: null,
      padding: { y: space[300], x: space[300] },
    },
    active: {
      background: palette.greyscale[10],
      border: null,
      padding: { y: space[300], x: space[300] },
    },
    focus: {
      background: palette.transparent,
      border: null,
      padding: { y: space[300], x: space[300] },
      shadow: shadows.focus.primary.full,
    },
    selected: {
      background: palette.primary[10],
      border: null,
      padding: { y: space[300], x: space[300] },
    },
    disabled: {
      background: palette.greyscale[10],
      border: null,
      padding: { y: space[300], x: space[300] },
    },
  },
  primary: {
    normal: {
      background: palette.inverted.white,
      border: borders.styles.thin.primary[20],
      padding: { y: space[300], x: space[500] },
    },
    hover: {
      background: palette.inverted.white,
      border: borders.styles.thin.primary[20],
      padding: { y: space[300], x: space[500] },
    },
    active: {
      background: palette.inverted.white,
      border: borders.styles.thin.primary[20],
      padding: { y: space[300], x: space[500] },
    },
    focus: {
      background: palette.inverted.white,
      border: borders.styles.thin.primary[20],
      padding: { y: space[300], x: space[500] },
      shadow: shadows.focus.primary.full,
    },
    selected: {
      background: palette.primary[10],
      border: borders.styles.thin.primary[20],
      padding: { y: space[300], x: space[500] },
    },
    disabled: {
      background: palette.inverted.white,
      border: borders.styles.thin.grey[20],
      padding: { y: space[300], x: space[500] },
    },
  },
  secondary: {
    normal: {
      background: palette.inverted.white,
      border: borders.styles.thin.grey[20],
      padding: { y: space[300], x: space[500] },
    },
    hover: {
      background: palette.inverted.white,
      border: borders.styles.thin.grey[20],
      padding: { y: space[300], x: space[500] },
    },
    active: {
      background: palette.inverted.white,
      border: borders.styles.thin.grey[20],
      padding: { y: space[300], x: space[500] },
    },
    focus: {
      background: palette.inverted.white,
      border: borders.styles.thin.grey[20],
      padding: { y: space[300], x: space[500] },
      shadow: shadows.focus.primary.full,
    },
    selected: {
      background: palette.inverted.white,
      border: borders.styles.thin.grey[20],
      padding: { y: space[300], x: space[500] },
    },
    disabled: {
      background: palette.inverted.white,
      border: borders.styles.thin.grey[20],
      padding: { y: space[300], x: space[500] },

    },
  },
  alert: {
    normal: {
      background: palette.inverted.white,
      border: borders.styles.thin.alert[60],
      padding: { y: space[300], x: space[500] },
    },
    hover: {
      background: palette.inverted.white,
      border: borders.styles.thin.alert[70],
      padding: { y: space[300], x: space[500] },
    },
    active: {
      background: palette.inverted.white,
      border: borders.styles.thin.alert[80],
      padding: { y: space[300], x: space[500] },
    },
    focus: {
      background: palette.inverted.white,
      border: borders.styles.thin.alert[80],
      padding: { y: space[300], x: space[500] },
      shadow: shadows.focus.alert.full,
    },
    selected: {
      background: palette.inverted.white,
      border: borders.styles.thin.alert[80],
      padding: { y: space[300], x: space[500] },
    },
    disabled: {
      background: palette.inverted.white,
      border: borders.styles.thin.grey[20],
      padding: { y: space[300], x: space[500] },
    },
  },
} as const;

const textVariants = {
  ghost: {
    normal: 'ghost',
    selected: 'primary',
  },
  primary: {
    normal: 'primary',
    selected: 'primary',
  },
  secondary: {
    normal: 'secondary',
    selected: 'secondary',
  },
  alert: {
    normal: 'destructive',
    selected: 'destructive',
  },
} as const;

type Variant = keyof typeof variants;

type ButtonProps = MuiButtonProps & Readonly<{
  colorVariant: Variant;
  selected: boolean;
}>;

const buttonPadding = ({ colorVariant }: ButtonProps) => {
  const variant = variants[colorVariant];

  return css`
    & ${/* sc-selector */ ActionText} > div {
      padding: ${variant.normal.padding.y} ${variant.normal.padding.x};
    }
  `;
};

const buttonColors = ({ disabled = false, colorVariant, selected }: ButtonProps) => {
  const variant = variants[colorVariant];

  if (disabled) {
    return css`
      background-color: ${variant.disabled.background};
      border: ${variant.disabled.border};

      &:hover {
        background-color: ${variant.disabled.background};
      }
    `;
  }

  if (selected) {
    return css`
      background-color: ${variant.selected.background};
      border: ${variant.selected.border};
      z-index: ${({ theme }) => theme.scTheme.zindex.selected};

      &.Mui-focusVisible,
      &:focus-visible,
      &:focus-within:not(:focus-visible) {
        box-shadow: ${variant.focus.shadow};
        z-index: ${({ theme }) => theme.scTheme.zindex.focused};
      }
    `;
  }

  return css`
    background-color: ${variant.normal.background};
    border: ${variant.normal.border};

    &:hover {
      background-color: ${variant.hover.background};
      border: ${variant.hover.border};
    }

    &:active {
      background-color: ${variant.active.background};
      border: ${variant.active.border};
    }

    &.Mui-focusVisible,
    &:focus-visible {
      background-color: ${variant.focus.background};
      border: ${variant.focus.border};
      box-shadow: ${variant.focus.shadow};
    }
  `;
};

const StyledButton = styled(MuiButton).withConfig({
  shouldForwardProp: (prop) => prop !== 'colorVariant' as string,
})<ButtonProps>`
  &&& {
    padding: ${({ theme }) => theme.scTheme.space[0]};
    position: relative;
    ${buttonColors}
    ${buttonPadding}

    &:disabled {
      cursor: not-allowed;
      pointer-events: all;
    }

    ${ButtonGroup} * &:not(:focus-within) {
      border-radius: inherit;
    }
  }
`;

export type Props = Omit<MuiButtonProps, 'variant'> & Readonly<{
  variant?: Variant;
  disabled?: boolean;
  children: ReactNode;
  selected?: boolean;
  loading?: boolean;
  loadingText?: string;
}>;

const Button = forwardRef(({
  variant = 'primary',
  disabled = false,
  selected = false,
  children,
  loading = false,
  loadingText,
  ...muiButtonProps
}: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const textVariant = textVariants[variant];

  return (
    <StyledButton
      {...muiButtonProps}
      {...(loading ? { onClick: noop } : {})}
      ref={ref}
      variant="contained"
      colorVariant={variant}
      disabled={disabled || loading}
      selected={selected}
    >
      <ActionText
        disabled={disabled || loading}
        variant={selected ? textVariant.selected : textVariant.normal}
      >
        {loading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box pr={300} display="flex">
              <CircularProgress size={space[500]} color="primary" />
            </Box>
            {loadingText}
          </Box>
        ) : (
          <Box display="inline-flex">
            {children}
          </Box>
        )}
      </ActionText>
    </StyledButton>
  );
});

export default Button;
