import type { Cache, Reference } from '@apollo/client';
import type { Modifier } from '@apollo/client/cache/core/types/common';
import merge from 'lodash/fp/merge';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import type { Get, ConditionalPick } from 'type-fest';

import { remove } from './array';
import { isEmpty } from './checks';
import compareValues, { castString, Change } from './compareValues';
import type { Literal } from './guards';
import {
  isObjectDeepPath, isArrayOf, isLiteral,
} from './guards';

export type Nullable<T> = T | null | undefined;

// for limiting recursive types' depth
type Decr = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

// like PartialDeep<Obj> from type-fest but ignores things that aren't plain objects

// type Thing = {
//   a: string;
//   b: {
//     c: {
//       d: number[];
//     };
//     e: Date;
//   };
// };
//
// PartialDeep<Thing> === {
//   a?: string
//   b?: {
//     c?: {
//       d?: number[]
//     },
//     e?: Date
//   }
// }

export type PartialDeepObject<Obj> = Obj extends Literal | Nullable<Literal> ? {
  [key in keyof Obj]?: PartialDeepObject<Obj[key]>
} : Obj;

export type ReplaceObjectValues<
Obj, ToReplace, Replacement> = Obj extends Literal | Partial<Literal>
  ? { [ key in keyof Obj]: ReplaceObjectValues<Obj[key], ToReplace, Replacement> }
  : Obj extends ToReplace
    ? Replacement
    : Obj;

export type ObjectShape<Obj, DefaultValue = unknown> = Obj extends Literal | Partial<Literal> ? {
  [key in keyof Obj]: ObjectShape<Obj[key], DefaultValue>
} : DefaultValue;

type CacheModifyFn<T> = (...args: Parameters<Modifier<T>>) => T | (T extends unknown[]
  ? Reference[] : Reference);

export type CacheModifyOptions<T extends Literal> = Cache.ModifyOptions & Readonly<{
  fields: Readonly<{
    [key in keyof T]: CacheModifyFn<T[key]>
  }>;
}>;

export type ObjectComparisonResult<T> = T extends Literal
  ? { [key in keyof T]: ObjectComparisonResult<T[key]> }
  : Change;

// Values that are currently in inputs are usually not parsed
// meaning that even though a field is yup.number() in schema, its value is actually a string
// Same goes for yup.date() and problably some others.

export type ReplaceFormValues<Obj, ToReplace, Replacement> = Obj extends Literal | Partial<Literal>
  ? { [ key in keyof Obj]: ReplaceFormValues<Obj[key], ToReplace, Replacement> }
  : Obj extends ToReplace
    ? Replacement
    : Obj extends (infer T)[]
      ? ReplaceFormValues<T, ToReplace, Replacement>[]
      : Obj;

export type FormValues<T extends Literal> = ReplaceFormValues<
ReplaceFormValues<T, Date, Date | null>, number, number | string>;

type GetValue<T> = T extends Record<'value', infer U>
  ? GetValue<U>
  : T extends Partial<Record<'value', infer U>>
    ? GetValue<U> | undefined
    : T;

export type FlattenValues<Obj> = Obj extends Partial<Literal>
  ? Obj extends Partial<Record<'value', unknown>>
    ? GetValue<Obj>
    : { [key in keyof Obj]: GetValue<Obj[key]> }
  : Obj;

export type DeepRequired<T> = T extends Literal
  ? { [key in keyof T]-?: DeepRequired<T[key]> }
  : T extends null | undefined
    ? Exclude<T, undefined> | null
    : T;

// Merges proerties from ObjA and ObjB, (ObjB values override ones from ObjA).
// Plain object values are also merged recursively

export type MergeDeepObject<ObjA extends Literal, ObjB extends Literal> = {
  [key in Exclude<keyof ObjA, keyof ObjB>]: ObjA[key]
} & {
  [key in Exclude<keyof ObjB, keyof ObjA>]: ObjB[key]
} & {
  [key in Extract<keyof ObjA, keyof ObjB>]: ObjB[key] extends Literal
    ? MergeDeepObject<ObjA[key] extends Literal ? ObjA[key] : ObjB[key], ObjB[key]>
    : ObjB[key]
};

// Creates an union of object's values, including nested properties.
// type Thing = {
//   a: number;
//   b: {
//     c: string;
//     d: Date[]
//   }
// }
//
// DeepObjectValues<Thing> === number | string | Date[];

export type DeepObjectValues<T> = T extends Literal | undefined ? {
  [key in keyof T]: T[key] extends Literal | undefined ? DeepObjectValues<T[key]> : T[key]
}[keyof T] : never;

export type ObjectValues<T> = T[keyof T];
export type GetPathValue<Obj, Path> = Path extends string
  ? Path extends `${infer Current}.${infer Rest}`
    ? Current extends keyof Obj
      ? GetPathValue<Obj[Current], Rest>
      : never
    : Path extends keyof Obj
      ? Obj[Path]
      : never
  : never;

export type ToPathObject<T, Depth extends number> = T extends Literal | undefined ? {
  [K in keyof T as (K | `${K & string}${Depth extends 1 ? '' : T[K] extends Literal | undefined ? `.${string & keyof ToPathObject<T[K], Decr[Depth]>}` : ''}`)]: never
} : never;

export type ObjectPaths<T> = T extends Literal ? keyof ToPathObject<Required<T>, 5> : never;

type ToDeepOnlyPathObject<T> = Required<T> extends Literal ? {
  [K in keyof Required<T> as `${K & string}${Required<T>[K] extends Literal ? `.${string & keyof ToDeepOnlyPathObject<Required<T>[K]>}` : ''}`]: never
} : never;

export type DeepObjectPaths<T> = T extends Literal ? keyof ToDeepOnlyPathObject<T> : never;

export type FormPrefixes<T> = T extends Literal ? keyof ConditionalPick<{
  [key in keyof ToPathObject<T, 5>]-?: Get<T, key & string> extends Literal | unknown[] | undefined
    ? true : false;
}, true> : never;

export type NonMutable<T> = T extends (infer Item)[] ? (Item[] | readonly Item[]) : T;
export type NonMutableObject<T> = T extends Literal ? Readonly<{
  [key in keyof T]: NonMutable<T[key]>
}> : NonMutable<T>;

export type ShallowReplaceNullable<T extends Literal> = {
  [key in keyof T]: Exclude<T[key], null | undefined>;
};

type DeepPick<T, U extends string> = {
  [key in U as key extends `${infer Current}.${string}` ? Current : key]: key extends keyof T
    ? T[key]
    : key extends `${infer Current}.${infer Rest}`
      ? Current extends keyof T
        ? DeepPick<T[Current], Rest>
        : never
      : never
};

export type DeepPickObject<T extends Literal, U extends ObjectPaths<T> & string> = DeepPick<T, U>;

/**
 * Replaces values which match guard with values returned by replacer.
 * Used for replacing all Date objects with their formatted values (forms)
 *
 * @param obj object to map
 * @param guard function to determine which values to replace (e.g isDate, isNumber)
 * @param replacer function which replaces matched values
 */
export const replaceObjectValues = <Obj extends Literal, ToReplace, Replacement>(
  base: Obj,
  guard: (value: unknown) => value is ToReplace,
  replacer: (value: ToReplace, path: DeepObjectPaths<Obj>) => Replacement,
): ReplaceObjectValues<Obj, ToReplace, Replacement> => {
  const recurse = (obj: Literal, prefix = ''): ReplaceObjectValues<Obj, ToReplace, Replacement> => mapValues(obj, (value, key) => {
    const path = `${prefix}${key}`;

    if (guard(value)) {
      return replacer(value, path as DeepObjectPaths<Obj>);
    }

    if (isLiteral(value)) {
      return recurse(value, `${path}.`);
    }

    if (isArray(value)) {
      return value.map((element: unknown, i) => {
        const nodePath = `${path}.[${i}]`;

        if (isLiteral(element)) {
          return recurse(element, `${nodePath}.`);
        }

        return guard(element)
          ? replacer(element, nodePath as DeepObjectPaths<Obj>)
          : element;
      }) as Replacement[];
    }

    return value;
  }) as ReplaceObjectValues<Obj, ToReplace, Replacement>;

  return recurse(base);
};

const getValue = <T>(obj: T): GetValue<T> => (isLiteral(obj) && 'value' in obj
  ? getValue(obj.value)
  : obj) as GetValue<T>;

export const flattenValues = <Obj extends Literal>(
  obj: Obj,
): FlattenValues<Obj> => {
  if ('value' in obj) {
    return getValue(obj) as FlattenValues<Obj>;
  }

  return mapValues(obj, (fieldValue) => {
    if (isLiteral(fieldValue)) {
      if ('value' in fieldValue) {
        return getValue(fieldValue);
      }

      return mapValues(fieldValue, (value) => (isLiteral(value)
        ? flattenValues(value)
        : value));
    }

    return fieldValue;
  }) as FlattenValues<Obj>;
};

type PickerFn<Obj> = (objValue: DeepObjectValues<Obj>, path: DeepObjectPaths<Obj>) => boolean;

export const pickDeep = <Obj extends Literal>(
  base: Obj,
  pickerFN: PickerFn<Obj>,
): PartialDeepObject<Obj> => {
  const recurse = (obj: Literal, prefix = '') => Object.entries(obj).reduce<Record<string, unknown>>((acc, [key, value]) => {
    const path = `${prefix}${key}`;

    if (isLiteral(value)) {
      // since PickerFn<Obj> already includes every possible leaf node type
      // this cast doesnt really matter
      const child = recurse(value, `${path}.`);
      if (!isEmpty(child)) {
        acc[key] = child;
      }

      // this one casts the "value" type to the type derrived from every value in this object
    } else if (pickerFN(value as DeepObjectValues<Obj>, path as DeepObjectPaths<Obj>)) {
      acc[key] = value;
    }

    return acc;
  }, {}) as PartialDeepObject<Obj>;

  return recurse(base);
};

export const compareObjectValues = <ObjA extends Literal, ObjB extends ObjectShape<ObjA>>(
  previousValue?: ObjA,
  currentValue?: ObjB,
): ObjectComparisonResult<ObjB> => {
  const base = merge(previousValue, currentValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return mapValues<ObjB, any>(base, (_, key: keyof ObjA & keyof ObjB) => {
    const previous = isLiteral(previousValue) ? previousValue[key] : previousValue;
    const current = isLiteral(currentValue) ? currentValue[key] : currentValue;

    if (isLiteral(previous) && isLiteral(current)) {
      return compareObjectValues(previous, current as ObjectShape<typeof previous>);
    }

    if (Array.isArray(previous) && Array.isArray(current)) {
      if (previous.length !== current.length) {
        return Change.unknown;
      }

      if (isArrayOf(previous, isLiteral) && isArrayOf(current, isLiteral)) {
        const compared = previous.map((item, index) => compareObjectValues(item, current[index]));
        return compared.every((item) => isEmpty(pickDeep(item as Literal, (value) => value !== Change.none)))
          ? Change.none
          : Change.unknown;
      }

      return previous.every(
        (item, index) => compareValues(castString(current[index]), castString(item)) === Change.none,
      ) ? Change.none : Change.unknown;
    }

    return compareValues<unknown>(castString(previous), castString(current));
  }) as ObjectComparisonResult<ObjB>;
};

export type GetDiffArgs<
InitialDataShape extends Literal, InputShape extends ObjectShape<InitialDataShape>> = Readonly<{
  initialData: InitialDataShape;
  userInput: InputShape;
  omitFields?: (
    fieldName: DeepObjectPaths<InitialDataShape> | DeepObjectPaths<InputShape>) => boolean;
}>;

export const getDiff = <InitialDataShape extends Literal, InputShape extends ObjectShape<InitialDataShape>>({
  omitFields,
  initialData,
  userInput,
}: GetDiffArgs<InitialDataShape, InputShape>): PartialDeepObject<ObjectComparisonResult<InputShape>> => pickDeep(
    compareObjectValues(initialData, userInput),
    (change, path) => {
      if (change === Change.none) {
        return false;
      }

      if (isNil(omitFields)) {
        return true;
      }

      if (isObjectDeepPath(initialData, path) || isObjectDeepPath(userInput, path)) {
        return !omitFields(path);
      }

      return true;
    },
  );

export const fromEntries = <Keys extends string, Values>(
  arg: readonly (readonly [Keys, Values])[],
): Record<Keys, Values> => Object.fromEntries(arg) as Record<Keys, Values>;

export const shallowCompare = <T extends Literal>(objectA: T, objectB: Literal, omitKeys?: (keyof T)[]): boolean => {
  const keys = Object.keys(objectA);
  const parsedKeys = isEmpty(omitKeys) ? keys : remove(omitKeys)(keys);

  if (parsedKeys.length !== Object.keys(objectB).length) {
    return false;
  }

  return parsedKeys.some((key) => objectA[key] !== objectB[key as string]);
};
