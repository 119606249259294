import type {
  DocumentNode,
  LazyQueryHookOptions,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import isNil from 'lodash/isNil';
import type { UnionToIntersection } from 'type-fest';

import type {
  useAdminProjectsColorsProviderAllProjectsQuery,
  useAdminTimeReportBlockedMessageSpaceOwnerQuery,
  useAdminTimeReportOnCallDetailsModalQuery,
  useAdminBenefitSectionsAssignedBenefitVariantsQuery,
  useAdminTimeReportEntryModalLimitsQuery,
  useAdminInvoicesDrawerCreateInvoiceMutation,
  useAdminInvoicesDrawerRemoveInvoiceMutation,
  useAdminInvoicesDrawerUpdateInvoiceMutation,
  useInvoicesDrawerInvoicesLazyQuery as useAdminInvoicesDrawerInvoicesLazyQuery,
  useAdminTimeReportFinancesLazyQuery,
  useAdminTimeReportLeaveAndTrainingQuery,
  useAdminDirectReportingStructureQuery,
} from '../schema/admin';
import type {
  Exact,
  useUserProjectsColorsProviderAllProjectsQuery,
  useUserTimeReportBlockedMessageSpaceOwnerQuery,
  useUserTimeReportOnCallDetailsModalQuery,
  useUserBenefitSectionsAssignedBenefitVariantsQuery,
  useUserTimeReportEntryModalLimitsQuery,
  useUserInvoicesDrawerCreateInvoiceMutation,
  useUserInvoicesDrawerRemoveInvoiceMutation,
  useUserInvoicesDrawerUpdateInvoiceMutation,
  useInvoicesDrawerInvoicesLazyQuery as useUserInvoicesDrawerInvoicesLazyQuery,
  useUserTimeReportFinancesLazyQuery,
  useUserTimeReportLeaveAndTrainingQuery,
  useUserDirectReportingStructureQuery,
} from '../schema/user';
import type { ErrorTuple } from '../services/errors';
import { splitError } from '../services/errors';
import type { Literal } from '../services/guards';
import type { ExtractErrorTuple } from '../services/hooks/useToasts';
import type {
  GetPathValue, MergeDeepObject, ObjectValues, ToPathObject,
} from '../services/object';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApolloOptsType = any;
// eslint-disable-next-line @typescript-eslint/ban-types
type EmptyVariablesType = Readonly<{}>;

type AnyHookShape = (baseOptions: ApolloOptsType) => unknown;

type ReplaceEmptyVariables<Variables> = string extends keyof Variables ? EmptyVariablesType : Variables;

type InferVariables<Hook extends AnyHookShape> =
   Parameters<Hook>[0] extends QueryHookOptions<ApolloOptsType, infer Variables>
     ? ReplaceEmptyVariables<Variables>
     : NonNullable<Parameters<Hook>[0]> extends LazyQueryHookOptions<ApolloOptsType, infer Variables>
       ? ReplaceEmptyVariables<Variables>
       : EmptyVariablesType;

export type InterchangeableHooks<HookA extends AnyHookShape, HookB extends AnyHookShape> = Readonly<{
  hookA: HookA; hookB: HookB;
}>;

type InterchangeableQueriesFromQueryTypes<
HookA extends AnyHookShape,
QueryA,
HookB extends AnyHookShape,
QueryB> = Readonly<{
  hook: (...args: ApolloOptsType[]) => ReturnType<HookA> | ReturnType<HookB>;
  splitError: (
    response?: QueryA | QueryB | null
  ) => ErrorTuple<ExtractErrorTuple<QueryA>> | ErrorTuple<ExtractErrorTuple<QueryB>>;
}>;

export type InterchangeableQueries<HookA extends AnyHookShape, HookB extends AnyHookShape> =
ReturnType<HookA> extends QueryResult<infer QueryA, ApolloOptsType>
  ? ReturnType<HookB> extends QueryResult<infer QueryB, ApolloOptsType>
    ? InterchangeableQueriesFromQueryTypes<HookA, QueryA, HookB, QueryB>
    : never
  : ReturnType<HookA> extends QueryTuple<infer QueryA, ApolloOptsType>
    ? ReturnType<HookB> extends QueryTuple<infer QueryB, ApolloOptsType>
      ? InterchangeableQueriesFromQueryTypes<HookA, QueryA, HookB, QueryB>
      : never
    : ReturnType<HookA> extends MutationTuple<infer MutationA, ApolloOptsType>
      ? ReturnType<HookB> extends MutationTuple<infer MutationB, ApolloOptsType>
        ? InterchangeableQueriesFromQueryTypes<HookA, MutationA, HookB, MutationB>
        : never
      : never;

type MergeVariables<VariablesA, VariablesB> = VariablesA extends Exact<infer VarA>
  ? VariablesB extends Exact<infer VarB>
    ? Partial<MergeDeepObject<VarA, VarB>>
    : Partial<VarA>
  : VariablesB extends Exact<infer VarB>
    ? Partial<VarB>
    : never;

export type MergeQueries<HookA extends AnyHookShape, HookB extends AnyHookShape> =
ReturnType<HookA> extends QueryResult<infer QueryA, ApolloOptsType>
  ? ReturnType<HookB> extends QueryResult<infer QueryB, ApolloOptsType>
    ? (
      baseOptions: QueryHookOptions<QueryA, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
      | QueryHookOptions<QueryB, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
    ) => ReturnType<HookA> | ReturnType<HookB>
    : never
  : ReturnType<HookA> extends QueryTuple<infer QueryA, ApolloOptsType>
    ? ReturnType<HookB> extends QueryTuple<infer QueryB, ApolloOptsType>
      ? (
        baseOptions: LazyQueryHookOptions<QueryA, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
        | LazyQueryHookOptions<QueryB, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
      ) => ReturnType<HookA> | ReturnType<HookB>
      : never
    : ReturnType<HookA> extends MutationTuple<infer MutationA, ApolloOptsType>
      ? ReturnType<HookB> extends MutationTuple<infer MutationB, ApolloOptsType>
        ? (
          baseOptions?: MutationHookOptions<MutationA, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
          | MutationHookOptions<MutationB, MergeVariables<InferVariables<HookA>, InferVariables<HookB>>>
        ) => ReturnType<HookA> | ReturnType<HookB>
        : never
      : never;

type DeriveRequiredHooks<T> = T extends InterchangeableHooks<infer HookA, infer HookB>
  ? InterchangeableQueries<HookA, HookB>
  : T extends Literal ? {
    [key in keyof T]: DeriveRequiredHooks<T[key]>
  } : T;

type DeriveAllPossibleVariables<T extends Literal> = UnionToIntersection<ObjectValues<{
  [key in keyof ToPathObject<T, 3>]: GetPathValue<T, key> extends InterchangeableHooks<infer HookA, infer HookB>
    ? MergeVariables<InferVariables<HookA>, InferVariables<HookB>> : never;
}>>;

type NonSplittableErrors<Config, HooksToOmit> = Config extends InterchangeableQueries<infer HookA, infer HookB>
  ? InterchangeableQueries<HookA, HookB>
  : Config extends Literal ? {
    [key in keyof Config]: key extends HooksToOmit
      ? Omit<NonSplittableErrors<Config[key], HooksToOmit>, 'splitError'>
      : NonSplittableErrors<Config[key], HooksToOmit>;
  } : Config;

export type RequiredHooksConfig = Readonly<{
  gql: {
    queries: {
      useProjectColors: InterchangeableHooks<
        typeof useAdminProjectsColorsProviderAllProjectsQuery,
        typeof useUserProjectsColorsProviderAllProjectsQuery
      >;
      useUserSpaceOwner: InterchangeableHooks<
        typeof useAdminTimeReportBlockedMessageSpaceOwnerQuery,
        typeof useUserTimeReportBlockedMessageSpaceOwnerQuery
      >;
      useOnCallMultipliers: InterchangeableHooks<
        typeof useAdminTimeReportOnCallDetailsModalQuery,
        typeof useUserTimeReportOnCallDetailsModalQuery
      >;
      useTimeReportFinances: InterchangeableHooks<
        typeof useAdminTimeReportFinancesLazyQuery,
        typeof useUserTimeReportFinancesLazyQuery
      >;
      useBenefitSectionsAssignedBenefitVariantsQuery: InterchangeableHooks<
        typeof useAdminBenefitSectionsAssignedBenefitVariantsQuery,
        typeof useUserBenefitSectionsAssignedBenefitVariantsQuery
      >;
      useTimeReportEntryModalLimitsQuery: InterchangeableHooks<
        typeof useAdminTimeReportEntryModalLimitsQuery,
        typeof useUserTimeReportEntryModalLimitsQuery
      >;
      useInvoicesDrawerInvoicesLazyQuery: InterchangeableHooks<
        typeof useAdminInvoicesDrawerInvoicesLazyQuery,
        typeof useUserInvoicesDrawerInvoicesLazyQuery
      >;
      useTimeReportLeaveAndTrainingQuery: InterchangeableHooks<
        typeof useAdminTimeReportLeaveAndTrainingQuery,
        typeof useUserTimeReportLeaveAndTrainingQuery
      >;
      useDirectReportingStructureQuery: InterchangeableHooks<
        typeof useAdminDirectReportingStructureQuery,
        typeof useUserDirectReportingStructureQuery
      >;
    };
    mutations: {
      useInvoicesDrawerCreateInvoiceMutation: InterchangeableHooks<
        typeof useAdminInvoicesDrawerCreateInvoiceMutation,
        typeof useUserInvoicesDrawerCreateInvoiceMutation
      >;
      useInvoicesDrawerRemoveInvoiceMutation: InterchangeableHooks<
        typeof useAdminInvoicesDrawerRemoveInvoiceMutation,
        typeof useUserInvoicesDrawerRemoveInvoiceMutation
      >;
      useInvoicesDrawerUpdateInvoiceMutation: InterchangeableHooks<
        typeof useAdminInvoicesDrawerUpdateInvoiceMutation,
        typeof useUserInvoicesDrawerUpdateInvoiceMutation
      >;
    };
  };
}>;

export type RequiredDocuments = Readonly<{
  userProjectsIds: DocumentNode;
  timeReportEntryModalLimits: DocumentNode;
}>;

export type RequiredHooks = NonSplittableErrors<DeriveRequiredHooks<RequiredHooksConfig>, 'useBenefitSectionsAssignedBenefitVariantsQuery' | 'useInvoicesDrawerInvoicesLazyQuery' | 'useTimeReportEntryModalLimitsQuery' | 'useTimeReportFinances' | 'useTimeReportLeaveAndTrainingQuery'>;
export type CommonVariables = DeriveAllPossibleVariables<RequiredHooksConfig['gql']>;

export type MakeHooksConfigArg = Readonly<{
  useUserSpaceOwner: ObjectValues<RequiredHooksConfig['gql']['queries']['useUserSpaceOwner']>;
  useProjectColors: ObjectValues<RequiredHooksConfig['gql']['queries']['useProjectColors']>;
  useOnCallMultipliers: ObjectValues<RequiredHooksConfig['gql']['queries']['useOnCallMultipliers']>;
  useTimeReportFinances: ObjectValues<RequiredHooksConfig['gql']['queries']['useTimeReportFinances']>;
  useDirectReportingStructureQuery: ObjectValues<RequiredHooksConfig['gql']['queries']['useDirectReportingStructureQuery']>;
  useBenefitSectionsAssignedBenefitVariantsQuery: ObjectValues<RequiredHooks['gql']['queries']['useBenefitSectionsAssignedBenefitVariantsQuery']>;
  useTimeReportEntryModalLimitsQuery: ObjectValues<RequiredHooksConfig['gql']['queries']['useTimeReportEntryModalLimitsQuery']>;
  useInvoicesDrawerInvoicesLazyQuery: ObjectValues<RequiredHooksConfig['gql']['queries']['useInvoicesDrawerInvoicesLazyQuery']>;
  useInvoicesDrawerCreateInvoiceMutation: ObjectValues<RequiredHooksConfig['gql']['mutations']['useInvoicesDrawerCreateInvoiceMutation']>;
  useTimeReportLeaveAndTrainingQuery: ObjectValues<RequiredHooks['gql']['queries']['useTimeReportLeaveAndTrainingQuery']>;
  useInvoicesDrawerRemoveInvoiceMutation: ObjectValues<RequiredHooksConfig['gql']['mutations']['useInvoicesDrawerRemoveInvoiceMutation']>;
  useInvoicesDrawerUpdateInvoiceMutation: ObjectValues<RequiredHooksConfig['gql']['mutations']['useInvoicesDrawerUpdateInvoiceMutation']>;
}>;

export const makeHooksConfig = ({
  useProjectColors,
  useUserSpaceOwner,
  useOnCallMultipliers,
  useTimeReportFinances,
  useBenefitSectionsAssignedBenefitVariantsQuery,
  useTimeReportEntryModalLimitsQuery,
  useInvoicesDrawerCreateInvoiceMutation,
  useInvoicesDrawerRemoveInvoiceMutation,
  useInvoicesDrawerUpdateInvoiceMutation,
  useInvoicesDrawerInvoicesLazyQuery,
  useTimeReportLeaveAndTrainingQuery,
  useDirectReportingStructureQuery,
}: MakeHooksConfigArg): RequiredHooks => ({
  gql: {
    mutations: {
      useInvoicesDrawerCreateInvoiceMutation: {
        hook: useInvoicesDrawerCreateInvoiceMutation,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('contractSalaryDetails' in response) {
            return splitError(response.contractSalaryDetails.createInvoiceDetails);
          }

          return splitError(response.reporting.invoiceDetailsApi.createInvoiceDetails);
        },
      },
      useInvoicesDrawerRemoveInvoiceMutation: {
        hook: useInvoicesDrawerRemoveInvoiceMutation,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('contractSalaryDetails' in response) {
            return splitError(response.contractSalaryDetails.removeInvoiceDetails);
          }

          return splitError(response.reporting.invoiceDetailsApi.removeInvoiceDetails);
        },
      },
      useInvoicesDrawerUpdateInvoiceMutation: {
        hook: useInvoicesDrawerUpdateInvoiceMutation,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('contractSalaryDetails' in response) {
            return splitError(response.contractSalaryDetails.updateInvoiceDetails);
          }

          return splitError(response.reporting.invoiceDetailsApi.updateInvoiceDetails);
        },
      },
    },
    queries: {
      useDirectReportingStructureQuery: {
        hook: useDirectReportingStructureQuery,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('profile' in response) {
            return splitError(response.profile);
          }

          return splitError(response.user);
        },
      },
      useTimeReportLeaveAndTrainingQuery: {
        hook: useTimeReportLeaveAndTrainingQuery,
      },
      useInvoicesDrawerInvoicesLazyQuery: {
        hook: useInvoicesDrawerInvoicesLazyQuery,
      },
      useTimeReportEntryModalLimitsQuery: {
        hook: useTimeReportEntryModalLimitsQuery,
      },
      useUserSpaceOwner: {
        hook: useUserSpaceOwner,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('profile' in response) {
            return splitError(response.profile);
          }

          return splitError(response.user);
        },
      },
      useProjectColors: {
        hook: useProjectColors,
        splitError: (response) => {
          if (isNil(response)) {
            return [null, null];
          }

          if ('projects' in response) {
            return splitError(response.projects);
          }

          return splitError(response.getUserProjects);
        },
      },
      useOnCallMultipliers: {
        hook: useOnCallMultipliers,
        splitError: (response) => splitError(response?.onCallMultipliers),
      },
      useTimeReportFinances: {
        hook: useTimeReportFinances,
      },
      useBenefitSectionsAssignedBenefitVariantsQuery: {
        hook: useBenefitSectionsAssignedBenefitVariantsQuery,
      },
    },
  },
});
