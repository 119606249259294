import qs from 'qs';

import type { NavGroup, UserGroup } from '../config/navigation';

import { isEmpty } from './checks';
import { isNotNil } from './guards';
import type { AnyRoutesConfig } from './hooks/useRoutes';
import type { NonMutable, Nullable } from './object';

const makeMatchesUserGroups = (
  userGroups: UserGroup[],
) => (group: string) => userGroups.some((userGroup) => userGroup.includes(group));

type BaseConfigObject = Readonly<{
  canOnlyBeAccessedBy?: Nullable<NonMutable<UserGroup[]>>;
}>;

const isAccessible = (item: BaseConfigObject, userGroups: UserGroup[]) => isEmpty(item.canOnlyBeAccessedBy)
|| item.canOnlyBeAccessedBy.some(makeMatchesUserGroups(userGroups));

export const removeInacessibleNavigationOptions = (
  userGroups: UserGroup[], navGroups: NonMutable<NavGroup[]>,
): NavGroup[] => navGroups.map((group): Nullable<NavGroup> => {
  const items = group.items.filter((item) => isAccessible(item, userGroups));

  return isEmpty(items) ? null : {
    ...group,
    items,
  };
}).filter(isNotNil);

export const removeInaccessibleRoutes = (
  userGroups: UserGroup[], routes: AnyRoutesConfig,
): AnyRoutesConfig => ({
  ...routes,
  paths: Object.fromEntries(Object.entries(routes.paths).filter(([, config]) => isAccessible(config, userGroups))),
});

export const makeLoginRedirectURL = (loginURL: string, baseURL: string): string => {
  const { search } = window.location;
  const { redirectUri = undefined } = qs.parse(search, { ignoreQueryPrefix: true });
  const relativeLocation = redirectUri ?? encodeURIComponent(window.location.href.replace(`${baseURL}/`, ''));

  return `${loginURL}?redirectUri=/${String(relativeLocation)}`;
};

export default {};
